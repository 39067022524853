<template>
    <div class="p-5">Create Serial Number</div>
</template>

<script>
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
export default {
    mixins: [BreadcrumbManager],
    mounted () {
        this.setButtonText('SAVE')
        this.setBreadcrumbs([
            {text: 'Serial Numbers', path: '/serial-numbers'},
            {text: 'New'}
        ])
    }
}
</script>